.xvia-catalog-card {
  border-radius: 7px;
  background: #fff;
  width: 100%;
  padding: 50px;
  margin-top: 30px;
}

.xvia-catalog-card__title_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  color: #000000;
}

.xvia-catalog-card__title_content {
  display: inline-block;
}

.xvia-catalog-card__info_content span {
  display: inline-block;
  padding: 5px;
}

.xvia-catalog-card__subtitle_text{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
