.xvia-top-services {
  background-color: white;
  padding: 20px;
}

.xvia-top-services .slick-slide {
  padding: 5px;
  vertical-align: top;
}

.xvia-top-services .slick-arrow {
  color: #bababa !important;
}

.xvia-top-services__container {
  text-align: center;
}

.xvia-top-services__title {
  font-size: 64px;
  color: #181b1d;
  margin-bottom: 40px;
}

.xvia-top-services__loading {
  height: 317px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-top-services__title {
    font-size: 20px;
  }

  .xvia-top-services {
    padding: 20px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-top-services__title {
    font-size: 50px;
  }

  .xvia-top-services {
    padding: 20px 0px;
  }
}

@media (max-width: 767px) {
  .xvia-top-services__title {
    font-size: 40px;
  }

  .xvia-top-services {
    padding: 20px 0px;
  }
}

.xvia-top-services__subtitle {
  color: #777777;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-top-services__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: center;
  vertical-align: top !important;
}
