/*
====> NEWS CARD
*/

.xvia-news-card {
  position: relative;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  background: #fff;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  padding: 0;
  max-width: 500px;
  overflow: hidden;
}

.xvia-news-card__thumb {
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-color: #f1e9e9;
}

.xvia-news-card__thumb {
  transition: all 0.3s linear 0s;
  align-items: flex-end;
  display: flex;
}

.xvia-news-card__thumb:hover {
  transform: scale(1.1);
}

.xvia-news-card__content {
  background: #fff;
  position: relative;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: start;
  color: #007932;
}

.xvia-news-card__category {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 0;
  text-align: justify;
  text-transform: uppercase;
}

.xvia-news-card__date {
  color: #777777;
  padding: 15px 0px;
}

.xvia-news-card__title {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 110px;
}
