.xvia-recommended-services {
  padding: 60px;
  background-color: white;
}

.xvia-recommended-services__container {
  text-align: center;
}

.xvia-recommended-services__title {
  font-size: 64px;
  color: #181b1d;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-recommended-services__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-recommended-services__title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .xvia-recommended-services__title {
    font-size: 40px;
  }
}

.xvia-recommended-services__subtitle {
  color: #777777;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-recommended-services__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: center;
}
